import { useStaticQuery, graphql } from 'gatsby';

const useBlogPosts = () => {
  const data = useStaticQuery<{
    allFile: {
      nodes: {
        name: string;
        extension: string;
        childMarkdownRemark: {
          excerptAst: any;
          htmlAst: any;
          excerpt: any;
          html: any;
          frontmatter: {
            title: string;
            url: string;
            image: any;
          };
        };
      }[];
    };
  }>(
    graphql`
      query PortfolioMarkdown {
        allFile(
          filter: { sourceInstanceName: { eq: "portfolio" } }
          sort: {fields: childrenMarkdownRemark___frontmatter___order}
          ) {
          nodes {
            name
            extension
            childMarkdownRemark {
              ...MarkdownRemarkFragment
            }
            sourceInstanceName
          }
        }
      }

      fragment MarkdownRemarkFragment on MarkdownRemark {
        excerpt
        html
        frontmatter {
          title
          url
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    `
  );

  return data;
};

export default useBlogPosts;
