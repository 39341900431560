import { FaGithub, FaEnvelope, FaLinkedin, FaMastodon } from 'react-icons/fa';

const content = {
  title: 'Get in Touch.',
  subtitle: 'Nimm Kontakt mit mir auf:',
  links: [
    {
      to: 'https://github.com/aequi42',
      text: 'aequi42',
      Icon: FaGithub,
      newTab: true,
    },
    {
      to: 'https://metalhead.club/@aequi',
      text: 'Mastodon',
      Icon: FaMastodon,
      newTab: true,
    },
    {
      to: 'https://www.linkedin.com/in/florian-wojtenek-41b969204/',
      text: 'LinkedIn',
      Icon: FaLinkedin,
      newTab: true,
    },
    {
      to: 'mailto:florian@bloody-water.de',
      text: 'E-Mail',
      Icon: FaEnvelope,
      newTab: true,
    },
  ],
};

export default content;
