import { FaNodeJs } from 'react-icons/fa';
import {
  SiDotNet,
  SiGraphql,
  SiReact,
  SiGatsby,
  SiDocker,
  SiStyledComponents,
  SiApollographql,
} from 'react-icons/si';
import { BiTestTube, BiCode } from 'react-icons/bi';
import { VscLock } from 'react-icons/vsc';
import { AiOutlineAntDesign } from 'react-icons/ai';

const content = {
  languages: [
    {
      title: 'JavaScript',
      level: 89,
      color: '#a484e9',
    },
    {
      title: 'C#',
      level: 80,
      color: '#31bff3',
    },
    {
      title: 'TypeScript',
      level: 74,
      color: '#f4889a',
    },
    {
      title: 'HTML',
      level: 87,
      color: '#ffaf68',
    },
    {
      title: 'CSS',
      level: 85,
      color: '#f6e683',
    },
    {
      title: 'Deutsch',
      level: 95,
      color: '#79d45e',
    },
    {
      title: 'Englisch',
      level: 80,
      color: '#31bff3',
    },
  ],
  technologies: [
    { title: 'ASP.Net', icon: SiDotNet, url: 'https://dotnet.microsoft.com/apps/aspnet', },
    { title: 'NodeJs', icon: FaNodeJs, url: 'https://nodejs.org/' },
    { title: 'GraphQL', icon: SiGraphql, url: 'https://graphql.org/' },
    { title: 'React', icon: SiReact, url: 'https://reactjs.org/' },
    { title: 'HotChocolate', icon: BiCode, url: 'https://chillicream.com/docs/hotchocolate/', },
    { title: 'Docker', icon: SiDocker, url: 'https://www.docker.com/' },
    { title: 'FakeItEasy', icon: BiTestTube, url: 'https://fakeiteasy.github.io/', },
    { title: 'GatsbyJs', icon: SiGatsby, url: 'https://www.gatsbyjs.com/' },
    { title: 'styled-components', icon: SiStyledComponents, url: 'https://styled-components.com', },
    { title: 'Apollo', icon: SiApollographql, url: 'https://apollographql.com/', },
    { title: 'Caddy', icon: VscLock, url: 'https://caddyserver.com/' },
    { title: 'AntDesign', icon: AiOutlineAntDesign, url: 'https://ant.design/', },
  ],
  misc: [{ title: 'Scrum' }, { title: 'OKR' }],
};

export default content;
