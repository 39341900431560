import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated } from 'components';

import content from './content';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const { tagline, title, subtitle } = content;

function Welcome() {
  const meData = useStaticQuery(graphql`
    query MePicture {
      file(name: { eq: "ich" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            aspectRatio: 1.8
            transformOptions: { cropFocus: SOUTHEAST }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  return (
    <>
      <GatsbyImage image={getImage(meData.file.childImageSharp)} alt={'Ich'} />
      <Typography variant='h3' color='textPrimary'>
        {tagline}
      </Typography>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{title}</Animated>
        </Typography>
      </Fade>
      <Typography variant='h3' color='textPrimary'>
        {subtitle}
      </Typography>
    </>
  );
}

export default Welcome;
