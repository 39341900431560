import React from 'react';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { SkillBar } from 'components';

import content from './content';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const { languages, technologies } = content;
export default function Skills() {
  const styles = useStyles();
  return (
    <>
      <Typography variant='h3' color='textPrimary'>
        Sprachen
      </Typography>
      <div style={{ marginBottom: '4rem' }}>
        {languages.map(l => (
          <SkillBar key={l.title} {...l} />
        ))}
      </div>
      <Typography variant='h3' color='textPrimary'>
        Technologien / Frameworks
      </Typography>
      <div className={styles.chips}>
        {technologies.map(({ title, icon: Icon, url }) =>
          url ? (
            <Chip
              key={title}
              icon={Icon && <Icon />}
              label={title}
              component='a'
              href={url}
              rel='noreferer'
              target='_blank'
              clickable
            />
          ) : (
            <Chip key={title} icon={Icon && <Icon />} label={title} />
          )
        )}
      </div>
    </>
  );
}
